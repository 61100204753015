import React from "react"
import { graphql, Link } from "gatsby"

import Title from "../components/Title"
import styles from "../templates/service-category.module.scss"
import pageStyles from "../pages/page.module.scss"

const ServicesTemplate = ({ data }) => {
  return (
    <>
      <div className={pageStyles.coverServices} />
      <div className={pageStyles.container}>
        <Title>Servicios</Title>
        <p>
          Explora nuestra cartera de servicios y encuentra el más adecuado para
          tus necesidades.
        </p>

        <div className={styles.itemsContainer}>
          {data.allMarkdownRemark.edges.map(item => (
            <Link
              to={`servicios/${item.node.fields.slug}`}
              className={styles.itemWrapper}
            >
              <img src={item.node.frontmatter.featured} />
              <h2>{item.node.frontmatter.title}</h2>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "service-category" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            featured
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ServicesTemplate
